<template>
  <div class="container">
    <Breadcrumb
      :customBreadcrumbList="[{ name: 'Home' }, { name: 'counseling' }]"
    >
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: { id: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item> Counseling {{ activeName }} </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <!-- <el-divider /> -->
    <!-- <h1>Counseling Details</h1> -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="Counseling Details" name="Details">
        <PrivateLessonInfo :students="students" :sessionClass="sessionClass" />
      </el-tab-pane>
      <el-tab-pane label="Counseling Contract" name="Contract" v-if="isRoleAdmin() || isRoleParent()">
        <el-tabs type="border-card" class="mb-4">
          <el-tab-pane
            :label="
              userMethods.displayName(
                student.student.first_name,
                student.student.last_name
              )
            "
            v-for="student in students"
            :key="student.id"
          >
            <Contract :key="student.id" :id="student.enrolled_session_id" />
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <!-- <el-tab-pane label="Counseling Billing" name="Billing" v-if="isRoleAdmin() || isRoleParent()">
        <el-tabs type="border-card">
          <el-tab-pane label="已支付賬單"></el-tab-pane>
          <el-tab-pane label="未支付賬單"></el-tab-pane>
        </el-tabs>
      </el-tab-pane> -->
    </el-tabs>
    <!-- <hr class="separate-line" /> -->
  </div>
</template>

<script>
import { instant, user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import PrivateLessonInfo from "@/views/private/PrivateLessonInfo";
import Contract from "@/views/private/Counseling/components/Contract";
import privateApi from "@/apis/private";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `Counseling Details - ${this.isOnlineText} ${this.sessionClass
        .subject || ""} - Ivy-Way Academy`
    };
  },
  computed: {
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    isOnlineText() {
      switch (this.sessionClass.course_session.is_online) {
        case 1:
          return "Online";
        case 0:
          return "In-person";
        default:
          return "";
      }
    }
  },
  data() {
    return {
      activeName: "Details",
      students: [],
      teacher: {},
      teachers: [],
      sessionClass: {
        course_session: {}
      }
    };
  },
  mixins: [roleMixin],
  components: {
    Breadcrumb,
    IsOnlineIcon,
    PrivateLessonInfo,
    Contract
  },
  async created() {
    await this.initialCustomLesson();
    await privateApi.getCounselingOrders(this.$route.params.id);
  },
  methods: {
    async initialCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.sessionClass = {
        ...customLesson.session_class
      };

      this.students = customLesson.student_class_tickets;
    }
  }
};
</script>
