var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb',{attrs:{"customBreadcrumbList":[{ name: 'Home' }, { name: 'counseling' }]}},[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'counselingClassesDetail',
            params: { id: _vm.$route.params.id }
          }}},[_c('IsOnlineIcon',{attrs:{"isOnline":_vm.sessionClass.course_session.is_online}}),_vm._v(" "+_vm._s(_vm.sessionClass.title)+" ")],1)],1),_c('el-breadcrumb-item',[_vm._v(" Counseling "+_vm._s(_vm.activeName)+" ")])],1)],2),_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"Counseling Details","name":"Details"}},[_c('PrivateLessonInfo',{attrs:{"students":_vm.students,"sessionClass":_vm.sessionClass}})],1),(_vm.isRoleAdmin() || _vm.isRoleParent())?_c('el-tab-pane',{attrs:{"label":"Counseling Contract","name":"Contract"}},[_c('el-tabs',{staticClass:"mb-4",attrs:{"type":"border-card"}},_vm._l((_vm.students),function(student){return _c('el-tab-pane',{key:student.id,attrs:{"label":_vm.userMethods.displayName(
              student.student.first_name,
              student.student.last_name
            )}},[_c('Contract',{key:student.id,attrs:{"id":student.enrolled_session_id}})],1)}),1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }