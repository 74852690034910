<template>
  <div>
    <template v-if="isRoleAdmin()">
      <div>
        <div>
          <h4>請上傳文件</h4>
          <hr class="separate-line" />
          <div v-loading="fileLoading" element-loading-text="Uploading">
            <div style="margin: 4px 0px;">
              <!-- <template v-if="enrolled_session.contract_url">
                <a
                  :href="contractUrl"
                  target="_blank"
                  v-if="!displayUploadContract"
                >
                  查看合約內容 <i class="fas fa-eye action-icon" />
                </a>
                <el-button
                  v-if="!displayUploadContract"
                  @click="displayUploadContract = true"
                  size="mini"
                  type="primary"
                >
                  編輯已上傳合約
                </el-button>
                <el-button
                  v-if="displayUploadContract"
                  @click="displayUploadContract = false"
                  size="mini"
                  type="danger"
                >
                  取消編輯
                </el-button>
              </template> -->
              <!-- <span v-else>當前暫無合約</span> -->
            </div>
            <template>
              <el-upload
                drag
                :action="postUrl"
                :headers="token"
                :data="{ type: 'contract' }"
                name="document"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <div>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    將文件拖到此處，或<em>點擊上傳</em>
                  </div>
                </div>
              </el-upload>
              <p>
                <a v-if="contractUrl" :href="contractUrl" target="_blank">
                  <i class="fas fa-file action-icon" />
                  {{ contractUrl }}
                </a>
              </p>
              <el-button type="success" @click="dialogVisible = true">
                <i class="fas fa-paper-plane"></i>
                發送通知信件
              </el-button>
              <el-dialog
                title="發送通知信件"
                :visible.sync="dialogVisible"
                width="60%"
                :before-close="handleClose"
              >
                <el-form ref="form" label-position="top">
                  <el-form-item label="信件語言">
                    <el-select v-model="lang">
                      <el-option label="繁體中文" value="tw" />
                      <el-option label="简体中文" value="cn" />
                      <el-option label="English" value="en" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="寄送對象">
                    <div v-if="enrolled_session.student_user_id && user">
                      <div>學生</div>
                      <el-checkbox v-model="sendToStudent">
                        {{
                          `${userMethods.displayName(
                            user.basic_info.first_name,
                            user.basic_info.last_name,
                            user.basic_info.chinese_name
                          )} - ${user.basic_info.email}`
                        }}
                      </el-checkbox>
                      <router-link
                        :to="{
                          name: 'ProfileForAdmin',
                          params: { id: user.basic_info.id }
                        }"
                      >
                        <i class="fas fa-edit action-icon" />
                      </router-link>
                      <div>家長</div>
                      <el-checkbox-group v-model="sendToParentEmails">
                        <div
                          :key="parent.email"
                          v-for="parent in user.student.parents"
                        >
                          <el-checkbox :label="parent.email">
                            {{
                              `${userMethods.displayName(
                                parent.first_name,
                                parent.last_name
                              )} - ${parent.email}`
                            }}
                            <router-link
                              :to="{
                                name: 'ProfileForAdmin',
                                params: { id: parent.pivot.parent_user_id }
                              }"
                            >
                              <i class="fas fa-edit action-icon" />
                            </router-link>
                          </el-checkbox>
                        </div>
                      </el-checkbox-group>
                    </div>
                    <div v-else>
                      <div>學生</div>
                      <el-checkbox v-model="sendToContactPerson">
                        {{
                          `${userMethods.displayName(
                            enrolled_session.first_name,
                            enrolled_session.last_name
                          )} - ${enrolled_session.email}`
                        }}
                      </el-checkbox>
                    </div>
                  </el-form-item>
                  <el-form-item label="其他寄送對象">
                    <el-input
                      v-model="otherEmails"
                      placeholder="如要寄送多個 Emails，每個 Email 之間請用逗號分隔。如：mail1@gmail.com,mail2@gmail.com"
                    />
                  </el-form-item>
                </el-form>
                <div style="margin: 8px 0px; text-align: right">
                  <el-button @click="dialogVisible = false">取消</el-button>
                  <el-button @click="storeAndaSendContract" type="primary">
                    發送
                  </el-button>
                </div>
              </el-dialog>
            </template>
          </div>
        </div>
        <div class="mt-4" v-if="false">
          <h4>已簽名合約</h4>
          <hr class="separate-line" />
          <div v-loading="fileLoading" element-loading-text="Uploading">
            <!-- <div style="margin: 4px 0px;">
              <template v-if="enrolled_session.signed_contract_url">
                <a
                  :href="signedContractUrl"
                  target="_blank"
                  v-if="!displayUploadSignedContract"
                >
                  查看已簽名合約內容 <i class="fas fa-eye action-icon" />
                </a>
                <el-button
                  v-if="!displayUploadSignedContract"
                  @click="displayUploadSignedContract = true"
                  size="mini"
                  type="primary"
                >
                  編輯已上傳簽名合約
                </el-button>
                <el-button
                  v-if="displayUploadSignedContract"
                  @click="displayUploadSignedContract = false"
                  size="mini"
                  type="danger"
                >
                  取消編輯
                </el-button>
              </template>
            </div> -->
            <template>
              <el-upload
                drag
                :action="postUrl"
                :headers="token"
                :data="{ type: 'contract' }"
                name="document"
                :show-file-list="false"
                :on-success="handleSignedContractSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <div>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    將文件拖到此處，或<em>點擊上傳</em>
                  </div>
                </div>
              </el-upload>
              <p>
                <a
                  v-if="signedContractUrl"
                  :href="signedContractUrl"
                  target="_blank"
                >
                  <i class="fas fa-file action-icon" />
                  {{ signedContractUrl }}
                </a>
              </p>
              <!-- <span style="color: red; font-size: 12px">
                * 上傳合約後記得點選「儲存」
              </span> -->
              <!-- <div style="text-align: right">
                <el-button @click="$emit('toggleContractDialog', false)">
                  {{ $t("button.cancel") }}
                </el-button>
                <el-button @click="storeSignedContract" type="primary">
                  {{ $t("button.save") }}
                </el-button>
              </div> -->
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-if="isRoleParent()">
      <div>
        <h4>合約相關文件</h4>
        <hr class="separate-line" />
        <template v-if="enrolled_session.contract_url">
          <a :href="enrolled_session.contract_url" target="_blank">
            <i class="fas fa-file"></i> 查看合約相關文件
          </a>
        </template>
        <template v-else>
          暫無合約
        </template>
      </div>
      <div class="mt-4">
        <h4>已簽名合約</h4>
        <hr class="separate-line" />
        <template v-if="enrolled_session.signed_contract_url">
          <a :href="enrolled_session.signed_contract_url" target="_blank">
            <i class="fas fa-file"></i> 查看已簽名合約
          </a>
        </template>
        <template v-else>
          暫無已簽名合約
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import JwtService from "@/common/jwt.service";
import enrollmentApi from "@/apis/enrollment";
import profileApi from "@/apis/profile";
import { user } from "@ivy-way/material";
import role from "@/mixins/role";

export default {
  props: {
    id: {
      type: String || Number,
      default: null
    }
  },
  mixins: [role],
  computed: {
    userMethods() {
      return user;
    },
    token() {
      return { Authorization: `Bearer ${JwtService.getToken()}` };
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "document";
    },
    hasContract() {
      if (this.enrolled_session && this.enrolled_session.contract_url) {
        return true;
      } else {
        return false;
      }
    },
    hasSignedContract() {
      if (this.enrolled_session && this.enrolled_session.signed_contract_url) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      user: null,
      sendToStudent: false,
      sendToParentEmails: [],
      sendToContactPerson: false,
      fileLoading: false,
      actionType: "viewContract",
      contractUrl: "",
      signedContractUrl: "",
      otherEmails: "",
      lang: "tw",
      displayUploadContract: false,
      displayUploadSignedContract: false,
      enrolled_session: null
    };
  },
  watch: {
    id() {
      this.actionType = "viewContract";
    }
  },
  async created() {
    this.getContract();
  },
  methods: {
    async getContract() {
      const enrollmentRes = await enrollmentApi.fetch({ id: this.id });
      this.enrolled_session = enrollmentRes.enrolled_session;

      if (this.enrolled_session.student_user_id && this.isRoleAdmin()) {
        const user = await profileApi.getUserData(
          this.enrolled_session.student_user_id
        );
        this.user = user;
      }

      if (this.enrolled_session.contract_url) {
        this.contractUrl = this.enrolled_session.contract_url;
        this.displayUploadContract = false;
      } else {
        this.displayUploadContract = true;
      }

      if (this.enrolled_session.signed_contract_url) {
        this.signedContractUrl = this.enrolled_session.signed_contract_url;
        this.displayUploadSignedContract = false;
      } else {
        this.displayUploadSignedContract = true;
      }
    },
    handleSignedContractSuccess(res) {
      this.signedContractUrl = res.document.url;
      this.fileLoading = false;
      // this.displayUploadSignedContract = false;
      this.storeSignedContract();
    },
    handleAvatarSuccess(res) {
      this.contractUrl = res.document.url;
      this.fileLoading = false;
      // this.displayUploadContract = false;
      this.storeContract();
    },
    beforeAvatarUpload() {
      this.fileLoading = true;
      return true;
    },
    async storeAndaSendContract() {
      if (this.contractUrl === "") {
        this.$message.warning("請上傳合約！");
        return;
      }

      let emails = [];

      if (this.enrolled_session.student_user_id) {
        emails = [...this.sendToParentEmails];
        if (this.sendToStudent) {
          emails.push(this.user.basic_info.email);
        }
      } else {
        if (this.sendToContactPerson) {
          emails.push(this.enrolled_session.email);
        }
      }

      if (this.otherEmails !== "") {
        const otherEmails = this.otherEmails.split(",");
        emails = [...emails, ...otherEmails];
      }

      if (emails.length === 0) {
        this.$message.warning("請選擇收件人！");
        return;
      }

      try {
        // await this.storeContract();
        await this.sendContract(emails);
        this.$message.success("發送成功！");
        this.getContract();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async sendContract(emails) {
      await enrollmentApi.sendContractOfEnrollment({
        id: this.id,
        emails: emails,
        lang: this.lang
      });
    },
    async storeContract() {
      await enrollmentApi.storeContractOfEnrollment({
        id: this.id,
        contract_url: this.contractUrl
      });
    },
    async storeSignedContract() {
      if (this.signedContractUrl === "") {
        this.$message.warning("請上傳已簽名合約！");
        return;
      }
      try {
        await enrollmentApi.storeSignedContractOfEnrollment({
          id: this.id,
          signed_contract_url: this.signedContractUrl
        });
        this.$emit("toggleContractDialog", false);
        this.$emit("reload");
        this.$message.success("儲存成功！");
        this.getContract();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

</style>
